// @ts-nocheck
import React, { Fragment } from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import CheckBox from './CheckBox';
import { getChartCookie, setCheatCookie, removeCookie } from '@/utils/cookie';
import IpxContainer from '@/components/IpxContainer';
const {
    commonData: { superCheats },
} = window.__initData;

class Cheats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: false,
            txt: '展开',
            checkList: [
                {
                    label: '清除分销商缓存',
                    checked: false,
                    action: ({ checked }) => {
                        if (checked) {
                            removeCookie('partnerId');
                        }
                    },
                },
                {
                    label: '关闭所有客服秘籍',
                    checked: getChartCookie() || false,
                    action: ({ checked }) => setCheatCookie(checked),
                },
            ],
        };
    }

    handleZoom = () => {
        this.setState(prevState => ({
            zoom: !prevState.zoom,
        }));

        setTimeout(() => {
            this.setState(prevState => ({
                txt: prevState.txt === '关闭' ? '展开' : '关闭',
            }));
        }, 800);
    };

    onChange = index => {
        const { checkList } = this.state;
        checkList[index].checked = !checkList[index].checked;
        this.setState({
            checkList: [...checkList],
        });
    };

    handleConfirm = async () => {
        const { checkList } = this.state;
        await Promise.all(checkList.map(item => Promise.resolve(item.action(item))));
        this.handleZoom();
    };

    render() {
        const { zoom, txt, checkList } = this.state;

        return superCheats ? (
            <IpxContainer>
                <div
                    className={classnames(styles['tag-container'], {
                        [styles['tag-container-zoom']]: zoom,
                    })}
                >
                    <div className={styles['tag-btn']} onClick={this.handleZoom}>
                        {txt}秘籍
                    </div>
                    <div
                        className={classnames(styles['cheats-list'], {
                            [styles['cheats-list-zoom']]: zoom,
                        })}
                    >
                        {checkList && (
                            <Fragment>
                                <div className={styles['check-box-container']}>
                                    {checkList.map((item, i) => (
                                        <CheckBox
                                            key={item.label}
                                            label={item.label}
                                            value={item.value}
                                            checked={item.checked}
                                            onChange={() => this.onChange(i)}
                                        />
                                    ))}
                                </div>
                                <div
                                    className={styles['btn']}
                                    onClick={this.handleConfirm}
                                >
                                    确定
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </IpxContainer>
        ) : null;
    }
}

export default Cheats;
