// @ts-nocheck
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import Icon from '@components/Icon';

import styles from './style.module.scss';

class CheckBox extends Component {
    constructor(props) {
        super(props);
    }

    handleOnchange = e => {
        const { onChange } = this.props;
        if (onChange) {
            onChange();
        }
    };

    render() {
        const { checked, label } = this.props;
        const btnClass = classnames({
            checked: checked,
        });
        return (
            <Fragment>
                <div
                    className={styles['checkbox-container']}
                    onClick={this.handleOnchange}
                >
                    <div
                        className={`${styles['checkbox-place']} ${styles[`${btnClass}`]}`}
                    >
                        <div className={styles['check-icon']}>
                            <Icon name="Tick" />
                        </div>
                    </div>
                    <div className={styles['checkbox-txt']}>{label}</div>
                </div>
            </Fragment>
        );
    }
}

export default CheckBox;
